import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import config from './config/default.blueprint.json';
import { fillTemplate } from '../../utils/fillTemplate';
//import { isSafari } from 'react-device-detect';

const is = require('is_js');

const checkForTour = () => {
    // if (isSafari) {
    //     return true;
    // }
    return false;
}

export const useMobileTour = () => {
    const [isTourOpen, setIsTourOpen] = useState<boolean>(checkForTour());
    const [isShowingMore, setIsShowingMore] = useState<boolean>(false);

    const toggleShowMore = () => {
        setIsShowingMore(!isTourOpen);
    }
    const closeTour = () => {
        setIsTourOpen(false);
    }
    const openTour = () => {
        setIsTourOpen(true);
    }

    const _getTourConfig = ({
        nop,
        type,
        joinNetworkDemoUrl,
    }: any) => {
        let _formattedConfig: any = [];
        const _tourConfig = config.tourConfig;
        if (_tourConfig
            && is.array(_tourConfig) &&
            is.not.empty(_tourConfig)
        ) {
            for (let i = 0; i < _tourConfig.length; ++i) {
                const tour = _tourConfig[i];
                if (tour?.selector && String(tour?.selector).includes('demo') && !joinNetworkDemoUrl) {
                    continue;
                }
                if (tour?.content) {
                    let _temp: any = { ...tour };
                    if (nop) {
                        _temp.content = fillTemplate(_temp.content, { nop: nop, type: type ?? 'Credentials' });
                    }
                    _formattedConfig.push(_temp);
                }
            }
        }
        return _formattedConfig;
    }
    
    return {
        isTourOpen,
        isShowingMore,
        toggleShowMore,
        closeTour,
        openTour,
        getTourConfig: _getTourConfig,
    }
}