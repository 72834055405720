import React, { useEffect, useState} from 'react';
import {
  useParams
} from "react-router-dom";
import config from '../../config/default.config.json';
import screen_config from './config/default-blueprint.json';
import { HttpServer } from '../../servers/HttpServer';
const { getJoinNetworkDetailUrl } = config?.servers?.http;
const http = new HttpServer();
const is = require('is_js');


export const usePasswordless = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [networkDetails, setNetworkDetails] = useState<any>();
    const [networkError, setNetworkError] = useState<any>();

        var qs: any = (function(a) {
        if (a == "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i)
        {
            var p=a[i].split('=', 2);
            if (p.length == 1)
                b[p[0]] = "";
            else
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    })(window.location.search.substr(1).split('&'));
    useEffect(() => {
        
        if (!qs?.nop || is.empty(qs?.nop)
            || !qs?.vc || is.empty(qs?.vc)
             || !qs?.tag || is.empty(qs?.tag)
        ) {
            setNetworkError('Error in parsing the network url');
        }
        else {
            setLoading(true);
            setNetworkDetails({
            networkOperator: qs?.nop,
            vertical: qs?.vc,
            tag: qs?.tag,
            type: 'credential',
            deepLinkingUrl: `${screen_config.deeplinkingUrl}&nop=${qs?.nop}&vc=${qs?.vc}&tag=${qs?.tag}`,
            qrCodeUrl: `${screen_config.qrCodeUrl}?tag=${qs?.tag}&nop=${qs?.nop}&vc=${qs?.vc}`
        })
        setLoading(false);
        }
        
        // http.post(getJoinNetworkDetailUrl, {
        //     network_operator: qs?.nop,
        //     vertical: qs?.vc,
        //     tag: qs?.tag
        // })
        //     .then(res => {
        //         window.history.replaceState(null, null, window.location.pathname);
        //         if (res?.data) {
        //             console.log('res data =>', res?.data?.data);
        //             setNetworkDetails(res?.data?.data);
        //         }
        //         setLoading(false);
        //     })
        //     .catch(err => {
        //         window.history.replaceState(null, null, window.location.pathname);
        //         console.log('error in retrieving joining network', err);
        //         setLoading(false);
        //         setNetworkError(err?.message);
        //     });
    }, []);

    return {
        queryParams: qs,
        loading: loading,
        networkDetails: networkDetails,
        networkError,
    }
}