import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useViewport } from 'react-viewport-provider';
import { isMobile } from 'react-device-detect';

import { QRCodeView } from './components/QRcodeScreen';
import { HttpServer } from '../../servers/HttpServer';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import config from './config/default-blueprint.json';
import { DeepLinking } from './components/deeplinking.mobile-v1';
import { LandingMobile } from './components/Landing.mobile';
import { MobileTour } from '../MobileTour/container';
import { usePasswordless } from './provider';
import { useDeeplink } from '../../providers/openApp/deeplink.provider'
import { Alert } from 'rsuite';
import { CountDown } from './components';

const bp = config.error;

export const PasswordLessLogin = () => {
    const { queryParams, loading, networkDetails, networkError } = usePasswordless();
    const { setup, } = useDeeplink();
    const detail: any = {};
    if (loading) {
        return <LogoSpinnerV />
    }
    if (networkDetails && isMobile) {
        return (
            <MobileTour
                nop={networkDetails?.networkOperator}
                type={networkDetails?.type}
                deepLinkingUrl={networkDetails?.deepLinkingUrl}
                joinNetworkDemoUrl={networkDetails?.joinNetworkDemoUrl}
            />
        )
    }
    if (networkDetails && !isMobile) {
        return (
            <QRCodeView
                qrValue={networkDetails?.qrCodeUrl}
                nop={networkDetails?.networkOperator}
                type={networkDetails?.type}
                logo={networkDetails?.logo}
            />
        )
    }
    if (networkError) {
        Alert.error(bp.message, 8000);
        setTimeout(() => {
            window.location.replace(bp.redirectUrl);
        }, [11000]);
        return (
            <CountDown seconds={10000} />
        )
    }
    return <View />
}